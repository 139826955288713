import React, { useEffect, useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Button, Content, H2, Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import { IllustrationInitiate, IllustrationPromote } from "@siteimprove/fancyvisuals";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Illustration } from "../../visuals/illustration/illustration";
import { SortField } from "../../tables-and-lists/column-header/column-header";
import { Table, invertDirection } from "../../tables-and-lists/table/table";
import { Accordion } from "./accordion";

export const Meta: DocPageMeta = {
	category: "Structure", // Which category does the component belong to?,
	title: "Accordion", // Should the name in the styleguide menu be different from the component name?,
	notepad: undefined, //Go to https://hackmd.io/ , create an account, and create a new note. Provide the url to the note here.
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Accordion"
			subTitle="Use this component to group content that can be hidden, as to reduce the need to scroll when presenting multiple sections of content on a single page."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Accordion} />
				<InlineMessage variant="warning">
					<Paragraph>
						Our structure components are strongly interconnected. Make sure to read the structure
						component <GatsbyLink to="/lib/components/Structure/🧭 Guide">guide</GatsbyLink>, to
						find out which components you need and how to combine them correctly.
					</Paragraph>
				</InlineMessage>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The <Code>Accordion</Code> component is based on the <Code>Card</Code> component, where
					the <Code>Card.Header</Code> is converted into a button. The heading functions as a
					control that enable users to reveal or hide the associated section of content.
				</Paragraph>
				<Paragraph>Terms for understanding accordions include:</Paragraph>
				<Ul
					items={[
						<>
							<b>Accordion Header</b>: Label for or thumbnail representing a section of content that
							also serves as a control for showing and hiding the section of content.
						</>,
						<>
							<b>Accordion Content</b>: Section of content associated with an accordion header.
						</>,
					]}
				/>
				<Header.H3>Basic Usage</Header.H3>
				<Paragraph>
					In its simplest form, the <Code>Accordion</Code> just needs the header content being set
					with the <Code>Accordion.Content</Code> subcomponent and the content being set with{" "}
					<Code>Accordion.Body</Code> subcomponent.
				</Paragraph>
				<Example fn={BasicUsage} />
				<Header.H3>Header Usage</Header.H3>
				<Paragraph>
					The <Code>Accordion</Code> takes props for the heading but <Code>alignment</Code>,{" "}
					<Code>tooltipText</Code>, and <Code>tooltipPlacement</Code> has been omitted since they
					are conflicting with accordion styling.
				</Paragraph>
				<Example fn={HeaderUsage} />
				<Paragraph>
					<Code>Accordion.Header</Code> can also be used with custom elements.
				</Paragraph>
				<Example fn={CustomHeaderUsage} />
				<Header.H3>Footer Usage</Header.H3>
				<Paragraph>
					The <Code>Accordion</Code> needs the footer being set with the{" "}
					<Code>Accordion.Footer</Code> subcomponent.
				</Paragraph>
				<Example fn={FooterUsage} />
				<Header.H3>Expand Usage</Header.H3>
				<Paragraph>
					The <Code>Accordion</Code> can be expanded/collapsed from outside the component. The
					component keeps the state itself whether it's expanded or not, but it will update it if
					the <Code>expanded</Code> prop changes. This can also be used to expand the{" "}
					<Code>Accordion</Code> by default by setting <Code>{"expanded={true}"}</Code>
				</Paragraph>
				<Example fn={ExpandUsage} />
				<Header.H3>Illustration Usage</Header.H3>
				<Paragraph>
					Both the header and content takes a <Code>ReactNode</Code> so they can both just be an
					illustration. But remember <Code>alt</Code> text!
				</Paragraph>
				<Example fn={IllustrationUsage} />
				<Header.H3>Table Usage</Header.H3>
				<Paragraph></Paragraph>
				<Example fn={TableUsage} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Accordion}
					initialProps={{
						"aria-label": "Accordion example",
						children: (
							<>
								<Accordion.Header>Accordion header</Accordion.Header>
								<Accordion.Body>
									<Content>
										<Paragraph>Example: An Accordion with footer</Paragraph>
									</Content>
								</Accordion.Body>
								<Accordion.Footer>
									<Content>Accordion footer</Content>
								</Accordion.Footer>
							</>
						),
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							<>
								Be mindful if you change focusable properties of the accordion header. Right now
								they are centered around revealing/hiding the content.
							</>,
							<>
								To add padding to the accordion content, use a content component. It can also be
								used to control the position and alignment of the accordion’s contents.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul items={[<>-</>]} />
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						This component comes with built-in accessibility, no extra work required. The accordion
						header has <Code>aria-expanded</Code> and <Code>aria-controls</Code> to indicate to
						screen-reader users whether the accordion expanded and which content it controls.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul items={[<>-</>]} />
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<Accordion>
		<Accordion.Header>Accordion header</Accordion.Header>
		<Accordion.Body>
			<Content>Accordion content goes here</Content>
		</Accordion.Body>
	</Accordion>
);

const HeaderUsage = () => (
	<Accordion>
		<Accordion.Header level="h1" lookalike="h3" lineHeight="multi-line">
			Accordion header content
		</Accordion.Header>
		<Accordion.Body>
			<Content>Accordion content goes here</Content>
		</Accordion.Body>
	</Accordion>
);

const CustomHeaderUsage = () => (
	<Accordion>
		<Accordion.Header>
			<div>
				<H2>Accordion header content</H2>
				<Paragraph tone="subtle">Accordion header description</Paragraph>
			</div>
		</Accordion.Header>
		<Accordion.Body>
			<Content>Accordion content goes here</Content>
		</Accordion.Body>
	</Accordion>
);

/**
 * @click button
 * */
const FooterUsage = () => (
	<Accordion>
		<Accordion.Header>Accordion header</Accordion.Header>
		<Accordion.Body>
			<Content>Accordion content goes here</Content>
		</Accordion.Body>
		<Accordion.Footer>
			<Content>Accordion footer</Content>
		</Accordion.Footer>
	</Accordion>
);

const ExpandUsage = () => {
	const [expanded, setExpanded] = useState(true);

	return (
		<>
			<Button onClick={() => setExpanded(!expanded)}>Toggle accordion</Button>

			<Accordion expanded={expanded}>
				<Accordion.Header>Accordion header</Accordion.Header>
				<Accordion.Body>
					<Content>Accordion content goes here</Content>
				</Accordion.Body>
			</Accordion>
		</>
	);
};

/**
 * @click button
 * */
const IllustrationUsage = () => (
	<Accordion>
		<Accordion.Header>
			<Illustration src={IllustrationPromote} alt="Promote content" size={"small"} />
		</Accordion.Header>
		<Accordion.Body>
			<Illustration src={IllustrationInitiate} alt="Initiate content" />
		</Accordion.Body>
	</Accordion>
);

const someData = [
	{ id: 1, title: "Lasagna", cookTime: 45, servings: 2 },
	{ id: 2, title: "Pancakes", cookTime: 20, servings: 4 },
	{ id: 3, title: "Sushi", cookTime: 90, servings: 6 },
	{ id: 4, title: "Cake", cookTime: 30, servings: 8 },
];

const TableUsage = () => {
	const [items, setItems] = useState(someData);
	const [sort, setSort] = useState<SortField<typeof items[0]>>({
		property: "title",
		direction: "asc",
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setItems(sortItems(items, sort));
		setLoading(false);
	}, [sort]);

	return (
		<Accordion>
			<Accordion.Header>Table</Accordion.Header>
			<Accordion.Body>
				<Table
					columns={[
						{
							header: {
								property: "title",
								content: "Dish",
								defaultSortDirection: "asc",
								"data-observe-key": "table-header-dish",
							},
							render: (dto) => dto.title,
							options: {
								isKeyColumn: true,
							},
						},
						{
							header: {
								content: "Cook Time",
								tooltip: "in minutes",
								"data-observe-key": "table-header-cook-time",
							},
							render: (dto) => dto.cookTime,
						},
						{
							header: {
								property: "servings",
								content: "Servings",
								tooltip: "in persons",
								notSortable: true,
								"data-observe-key": "table-header-servings",
							},
							render: (dto) => dto.servings,
						},
					]}
					items={items}
					sort={sort}
					setSort={(property, direction) => {
						setLoading(true);
						setSort({
							property: property,
							direction: property === sort.property ? invertDirection(sort.direction) : direction,
						});
					}}
					loading={loading}
					caption="Basic usage table"
				/>
			</Accordion.Body>
		</Accordion>
	);
};

function sortItems<TDto>(items: TDto[], sort: SortField<TDto>): TDto[] {
	return items.sort((a, b) => {
		const first = sort.direction === "asc" ? a : b;
		const second = sort.direction === "asc" ? b : a;
		const v1 = first[sort.property];
		const v2 = second[sort.property];
		if (typeof v1 === "string" && typeof v2 === "string") {
			return v1.localeCompare(v2, undefined, {
				numeric: true,
				sensitivity: "base",
			});
		}

		return v1 > v2 ? 1 : v2 > v1 ? -1 : 0;
	});
}
